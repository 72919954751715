import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Month } from './month';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isLoading: boolean;
  months: Month[];
  monthNames = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];

  constructor(private http: HttpClient, private currency: CurrencyPipe) {
    this.months = [];
  }

  ngOnInit(): void {
    const date = new Date();
    this.loadBookingMonth(date.getMonth(), date.getFullYear());
  }

  public loadNextMonth(): void {
    const lastMonth = this.months[this.months.length - 1];
    let month = lastMonth.month - 1;
    let year = lastMonth.year;
    if (month < 0) {
      month = 11;
      year--;
    }
    this.loadBookingMonth(month, year);
  }

  downloadCsv(month: Month) {
    const rows: string[][] = [];
    rows.push([`Kiosk Umsätze - ${this.monthNames[month.month]} ${month.year}`]);
    rows.push(['Artikel', 'Verkaeufe', 'Preis', 'Umsatz']);
    for (const article of month.articles) {
      rows.push([
        article.name,
        article.amount.toString(),
        this.currency.transform(article.price, 'EUR'),
        this.currency.transform(article.sales, 'EUR')
      ]);
    }

    let csvContent = 'data:text/csv;charset=utf-8,';
    rows.forEach(rowArray => {
      const row = rowArray.join(';');
      csvContent += row + '\r\n';
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `Kiosk Umsaetze ${month.month}-${month.year}.csv`);
    document.body.appendChild(link); // Required for FF

    link.click();
  }

  private loadBookingMonth(month: number, year: number): void {
    this.isLoading = true;
    chayns.showWaitCursor();

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${chayns.env.user.tobitAccessToken}`
      })
    };
    this.http
      .get<Month>(`https://us-central1-mindtraffic-f7bcc.cloudfunctions.net/ecommerceApp/api/kiosk/month?month=${month}&year=${year}`, httpOptions)
      // .get<Month>(`https://server.mindtraffic.de/evaluation/backend/api/kiosk/month?month=${month}&year=${year}`, httpOptions)
      // .get<Month>(`https://localhost:5000/api/kiosk/month?month=${month}&year=${year}`, httpOptions)
      .subscribe(bookingMonth => {
        console.log('get booking month', bookingMonth);
        if (bookingMonth.articles.length > 0) {
          this.months.push(bookingMonth);
        } else {
          this.loadBookingMonth(month - 1, year);
        }
        chayns.hideWaitCursor();
      }, err => {
        console.error(err);
        this.isLoading = false;
        chayns.hideWaitCursor();
      });
  }

}
